import React, { FC } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ModalSelectGuests from "components/ModalSelectGuests";

export interface CardCategory1Props {
  className?: string;
  taxonomy: TaxonomyType;
  size?: "large" | "normal";
}

const CardCategory1: FC<any> = ({
  className = "",
  data,
}) => {
  
  return (
    <>
<ModalSelectGuests renderChildren={ (modal:any) =>
  {
return <Link
to=""
onClick={ (e)=>{ e.preventDefault();
      modal.openModal(); }  }
className={`nc-CardCategory1 flex items-center ${className}`}
data-nc-id="CardCategory1"
>
  
<div>
  {/* <h2
    className={`${ "text-base"
    } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
  >
    
  </h2> */}
  <span
    className={`${
      "text-xs"
    } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
  >
    <div>
    {/* <h2
    className={`${ "text-base"
    } nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
  >
    
  </h2> */}
  {data.name} {data.surname} ({data.email})
  <h3>Liczba zapisów: {data.count}. Ostatnie szkolenie { new Date(data.last).toLocaleDateString("pl-PL")} </h3>
    
    </div>
  </span>
</div>
</Link>

  }}></ModalSelectGuests>

    </>
  );
};

export default CardCategory1;
