import React, { FC, useEffect, useState } from "react";
import createDOMPurify from 'dompurify';

import CartService from "services/cartService";
import { useCart } from "services/cartContext";
import "react-toastify/dist/ReactToastify.css";
import { TrashIcon } from "@heroicons/react/24/solid";

const CartListItems: FC<any> = ({ className = "", defaultOpen = false }) => {
  const DOMPurify = createDOMPurify(window);
  const { getItems, removeItem } = useCart();
  const [allItems, setAllItems] = useState([]);

  useEffect(() => {
    const items = CartService.getItems(getItems);
    setAllItems(items);
  }, [getItems]);

  const handleRemoveItem = (id: string) => {
 debugger;
    const updatedItems = allItems.filter((item:any) => item.data.id !== id);
    setAllItems(updatedItems);
    CartService.removeItem(id,removeItem); // Ensure your CartService has a removeItem method
  };

  const renderItem = (x: any) => {
    var data = x.data;

    return (
      <div
        className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6`}
        data-nc-id="FlightCard"
        key={data.idEvent}
      >
        <div className={`sm:pr-20 relative`} data-nc-id="FlightCard">
          <a href="##" className="absolute inset-0" />
          <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
            <div className="w-24 lg:w-32 flex-shrink-0">
              <div className="relative flex-shrink-0 w-24 h-24 rounded-2xl overflow-hidden">
                <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                  <img src={"/images/course/" + data.image} className="object-cover w-full h-full" alt="nc-imgs" />
                </div>
              </div>
            </div>

            <div className="block lg:hidden space-y-1">
              <div className="flex font-semibold">
                <div>
                  <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' })}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {data.start} - {data.end}
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden lg:block min-w-[150px] flex-[4]">
              <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' })}
              </div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                {data.start} - {data.end}
              </div>
            </div>

            <div className="lg:block flex-[10]">
              <div className="font-medium text-lg">{data.title}</div>
              <div className="text-sm text-neutral-500 font-normal mt-0.5">
                Szkolenie on-line prowadzone na żywo
              </div>
            </div>

            <div className="flex-[4] whitespace-nowrap sm:text-right">
              <div>
                <span className="text-xl font-semibold text-secondary-600">
                  {data.price}zł
                </span>
              </div>
              <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
                za osobę
              </div>
            </div>

            <div className="lg:flex flex-[2] justify-center sm:justify-end">
              <button
                onClick={() => handleRemoveItem(data.idEvent)}
                className={`relative text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
              >
                <TrashIcon className="w-7 h-7" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={className}>
      {allItems.map(item => renderItem(item))}
    </div>
  );
};

export default CartListItems;
