// CartContext.js
import React, { createContext, useContext, useState, useEffect  } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [items, setItems] = useState(() => {
    
    const savedItems = localStorage.getItem('cartItems');
    return savedItems ? JSON.parse(savedItems) : [];
  });

  const addItem = (item) => {
  
    const itemIndex = items.findIndex(x => x.id === item.id);
    if (itemIndex !== -1) {
      
      return false;
    } else {
     setItems((prevItems) => [...prevItems, item]);
     return true;
    }
    
    
  };

    // Save items to local storage whenever they change
    useEffect(() => {
      localStorage.setItem('cartItems', JSON.stringify(items));
    }, [items]);

  const removeItem = (id) => {
    setItems((prevItems) => prevItems.filter(item => item.id !== id));
  };

  const getItems = () => {
    return items;
  };

  return (
    <CartContext.Provider value={{ items, addItem, removeItem, getItems }}>
      {children}
    </CartContext.Provider>
  );
};
