import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const AuthorDescription: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          dr Aleksandra Szyller
        </h2>
        <p className="mt-10">
          Doktor nauk społecznych w dziedzinie pedagogiki, terapeuta, nauczyciel mianowany edukacji wczesnoszkolnej.
        </p>
        <p className="mt-5">
          Wieloletnia wykładowczyni, adiunkt na Wydziale Pedagogicznym Uniwersytetu Warszawskiego, koordynator praktyk przedszkolnych i wczesnoszkolnych, opiekun Koła Naukowego Wczesnej Edukacji WPUW.
        </p>
        <p className="mt-5">
          Prowadząca szkolenia dla nauczycieli edukacji przedszkolnej, wczesnoszkolnej, szkół podstawowych i liceów, wspomagania rad, m.in. w Warszawskim Centrum Innowacji Edukacyjnych i Społecznych (WCIES), Mazowieckim Samorządowym Centrum Doskonalenia Nauczycieli (MSCDN), Ośrodku Rozwoju Edukacji (ORE).
        </p>
        <p className="mt-5">
          Autorka książek i artykułów naukowych dotyczących edukacji początkowej, podręczników dla dzieci, scenariuszy zajęć dla uczniów, materiałów dydaktycznych dla nauczycieli.
        </p>
        <p className="mt-5">
          Prywatnie szczęśliwa żona i mama trzech synków.
        </p>
      </div>
      <div className="flex w-full justify-center items-center">
        <NcImage src={rightImg} className="w-200" />
      </div>
    </div>
  );
};

export default AuthorDescription;
