import axios from 'axios';
import ClientConfig from './clientConfig';
import AuthServiceClient from './authServiceClient';


export class RegistrationServiceClient {

    authService = new AuthServiceClient();

    async new(event_id, first_name, last_name, email, needsInvoice, invoiceDetails, paymentMethodId, additionalInfo, promoCode, callback) {


        try {
            axios.post(ClientConfig.ApiBaseUrl + "registration/new/",
                JSON.stringify({
                    "eventId": event_id,
                    "firstName": first_name,
                    "lastName": last_name,
                    "email": email,
                    "sex": first_name.toLowerCase().trim().slice(-1) == 'a' ? "F":"M",
                    "needsInvoice": needsInvoice ? 1 :0,
                    "invoiceDetails": invoiceDetails || "",
                    "paymentMethodId": paymentMethodId,
                    "additionalInfo": additionalInfo,
                    "promoCode": promoCode
                }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then(response => {
                
                callback(response.data);
            }).catch(c=>{
                
            })

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }


    async anew(event_id, first_name, last_name, email, needsInvoice, invoiceDetails, paymentMethodId, callback) {


        try {
            axios.post(ClientConfig.ApiBaseUrl + "registration/anew/",
                JSON.stringify({
                    "eventId": event_id,
                    "firstName": first_name,
                    "lastName": last_name,
                    "email": email,
                    "sex": first_name.toLowerCase().trim().slice(-1) == 'a' ? "F":"M",
                    "needsInvoice": needsInvoice ? 1 :0,
                    "invoiceDetails": invoiceDetails || "",
                    "paymentMethodId": paymentMethodId
                }), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.authService.authToken()
                }
            }
            ).then(response => {
                
                callback(response.data);
            }).catch(c=>{
                
            })

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    } 

    async anewbulk(eventId, bulktxt, callback) {


        try {
            axios.post(ClientConfig.ApiBaseUrl + "registration/bulk/",
                JSON.stringify({
                    "eventId": eventId,
                    "text": bulktxt,
                }), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.authService.authToken()
                }
            }
            ).then(response => {
                
                callback(response.data);
            }).catch(c=>{
                
            })

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    } 
}


export default RegistrationServiceClient;
