import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import createDOMPurify from 'dompurify';

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    airlines: {
      logo: string;
      name: string;
    };
    price: string;
  };
}

const FlightCard: FC<any> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const DOMPurify = createDOMPurify(window);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img src={"/images/course/" + data.image} className="w-10" alt="" />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                {/* TIME - NAME */}
                <div className="hidden lg:block  min-w-[150px] flex-[4] ">
                  <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {(new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' }))}
                  </div>
                  <div className="text-sm text-neutral-500 font-normal mt-0.5">
                    {data.start} - {data.end}
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  Monday, August 16 · 10:00
                </span>
                <span className=" font-semibold">
                  Singapore International Airport (SIN)
                </span>
              </div> */}
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            {/* <li>Trip time: 7 hours 45 minutes</li>
            <li>ANA · Business class · Boeing 787 · NH 847</li> */}
          </ul>
        </div>
      </div>
    );
  };

  const renderDetail = (data: any) => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        <h2 className="text-2xl font-semibold">Przykładowe zagadnienia</h2>
        <div className="mt-10 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="mt-10 text-neutral-6000 dark:text-neutral-300"></div>
        {
          <div id="desc" className="pl-4">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }} />
          </div>
        }

        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary href={"/checkout/" + data.idEvent} >Zapisz się</ButtonPrimary>
          <span className="px-2"></span>
          <ButtonSecondary href={"/event/" + data.idEvent}>Więcej szczegółów</ButtonSecondary>

        </div>

      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div onClick={() => setIsOpen(!isOpen)}
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">

            <div className="relative flex-shrink-0 w-24 h-24 rounded-2xl overflow-hidden"><div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
              <img src={"/images/course/" + data.image} className="object-cover w-full h-full" alt="nc-imgs" />


            </div>
            </div>


            {/* <img src={data.airlines.logo} className="w-10" alt="" /> */}
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                {/* TIME - NAME */}

                <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {(new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' }))}
                </div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {data.start} - {data.end}
                </div>


              </div>
            </div>

          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {(new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' }))}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.start} - {data.end}
            </div>
          </div>

          {/* TIMME */}
          <div className=" lg:block flex-[10]">
            <div className="font-medium text-lg">
              {data.title}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              Szkolenie on-line prowadzone na żywo
            </div>
          </div>


          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-600">
                {/* {data.price} */}
                {data.price}zł
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              za osobę
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail(data)}
    </div>
  );
};

export default FlightCard;
