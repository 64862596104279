import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import createDOMPurify from 'dompurify';
import { CartService } from "services/cartService";
import { MoonIcon } from "@heroicons/react/24/outline";
import { ShoppingCartIcon } from "@heroicons/react/24/solid";
import { useCart } from "services/cartContext";


const ShoppingCart: FC<any> = ({
  itemsCount = 0
}) => {

  const { items } = useCart();

  return (
<button onClick={ () => { window.location.href = "/checkout"}}
  className={`relative text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
>
  <ShoppingCartIcon className="w-7 h-7" aria-hidden="true" />
  <div
    className="absolute bottom-0 right-0 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
  >
     {items.length} 
  </div>
</button>

       
  );
};

export default ShoppingCart;
