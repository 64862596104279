import axios from 'axios';
import AuthServiceClient from './authServiceClient';
import ClientConfig from './clientConfig';

export class EventServiceClient {

    authService = new AuthServiceClient();

    async getone(id, callback) {

        try {
            await
                //axios.get("https://localhost:49157/api/event/list",
                axios.get(ClientConfig.ApiBaseUrl + "event/" + id,

                    {
                        //  responseType: 'arraybuffer',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            // 'Accept': 'application/pdf',
                            'Authorization': this.authService.authToken()
                        }
                    } 
                ).then(response => {

                    callback(response.data);
                });
        }
        catch (e) {
            console.log('EventService error.', e);
        }
    }



    async get(callback) {

        try {
            await
                //axios.get("https://localhost:49157/api/event/list",
                axios.get(ClientConfig.ApiBaseUrl + "event/list",

                    {
                        //  responseType: 'arraybuffer',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            // 'Accept': 'application/pdf',
                            'Authorization': this.authService.authToken()
                        }
                    }
                ).then(response => {

                    callback(response.data);
                });
        }
        catch (e) {
            console.log('EventService error.', e);
        }
    }

    async geta(callback) {

        try {
            await
                axios.get(ClientConfig.ApiBaseUrl + "event/alist",
                    //axios.get("https://mamtemoc.edu.pl/api/event/alist",

                    {
                        //  responseType: 'arraybuffer',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            // 'Accept': 'application/pdf',
                            'Authorization': this.authService.authToken()
                        }
                    }
                ).then(response => {

                    callback(response.data);

                });
        }
        catch (e) {
            console.log('EventService error.', e);
            window.location.href = "/login";
        }


    }

    async move(idEvent, newDate, callback) {

        try {
            axios.post(ClientConfig.ApiBaseUrl + "event/update/",
                JSON.stringify(
                    {
                        "idEvent": idEvent,
                        "date": newDate.getFullYear() + '-' + (newDate.getMonth()+1) + '-' + newDate.getDate(),
                    }),
                { headers: { 'Content-Type': 'application/json',
                'Authorization': this.authService.authToken() } }
            ).then(response => {
                callback();
            }).catch(aa=>
                {
                    window.location.href = "/login"
                });

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }

    async clone(idEvent, newDate, callback) {

        try {
            axios.post(ClientConfig.ApiBaseUrl + "event/clone/",
                JSON.stringify(
                    {
                        "idEvent": idEvent,
                        "date": newDate.getFullYear() + '-' + (newDate.getMonth()+1) + '-' + newDate.getDate(),
                    }),
                { headers: { 'Content-Type': 'application/json',
                'Authorization': this.authService.authToken() } }
            ).then(response => {
                
                callback();

            });

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }



}

export default EventServiceClient;
