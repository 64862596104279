import { Tab } from "@headlessui/react";
import { MoonIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useEffect, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import NcImage from "shared/NcImage/NcImage";
import Checkbox from "shared/Checkbox/Checkbox";
import { useParams } from "react-router-dom";
import RegistrationServiceClient from "services/registrationServiceClient";
import EventServiceClient from "services/eventServiceClient";
import EventModel from "services/models/eventModel";
import PaymentServiceClient from "services/paymentServiceClient";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import PromoCodeServiceClient from "services/promoCodeServiceClient";
import { Console } from "console";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const [needInvoice, setNeedInvoice] = useState<boolean | null>(false);
  let { id } = useParams();
  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,

  });


  const [title, setTitleValue] = useState<string>("");
  const [description, setDescriptionValue] = useState<string>("");
  const [date, setDateValue] = useState<string>("");

  const [start, setStartValue] = useState<string>("");
  const [end, setEndValue] = useState<string>("");

  const [image, setImageValue] = useState<string>("");

  const [firstName, setFirstNameValue] = useState<string>("");
  const [lastName, setLastNameValue] = useState<string>("");
  const [email, setEmailValue] = useState<string>("");
  const [needsInvoice, setneedsInvoiceValue] = useState<boolean>(false);
  const [invoiceDetails, setInvoiceDetailsValue] = useState<string>("");
  const [additionalInfo, setAdditionalInfoValue] = useState<string>("");
  const [paymentMethods, setPaymentMethodsValue] = useState<any[] | []>([]);
  const [paymentMethod, setPaymentMethodValue] = useState<any>({ value: "0" });
  const [promoCode, setPromoCodeValue] = useState<string>("");
  const [price, setPriceValue] = useState<string>("100");
  const [promoCodeMsg, showPromoCodeMessage] = useState<boolean>(false);
  
  
  const params = useParams();

  useEffect(() => {
    new EventServiceClient().get((data: any[]) => {

      var e = data.filter((x) => x.idEvent == params.id)[0];

      setTitleValue(e.title);
      setDateValue(e.date);
      setStartValue(e.start);
      setEndValue(e.end);
      setImageValue(e.image);
      setDescriptionValue(e.description);
      setInvoiceDetailsValue(e.invoiceDetails)

      var cb1 = document.getElementsByName('privacy-cb')[0] as HTMLInputElement;
      (cb1 as HTMLInputElement).setCustomValidity('Zaznacz zgodę');

      var cb2 = document.getElementsByName('regulations-cb')[0] as HTMLInputElement;
      (cb2 as HTMLInputElement).setCustomValidity('Zaznacz zgodę');

      new PaymentServiceClient().methods((data: any) => {
        setPaymentMethodsValue(data);
      })

    });
  }, []);


  const renderInvoiceFields = () => {

    //return (<div>[{needInvoice ? "aaa":"bbb"}]</div>)

    if (needInvoice)
      return <div>
        <div className="mb-5">
          <Label>Dane do faktury</Label>
          <Textarea required placeholder="NIP, ..." onChange={(e) => {
            (e.target as HTMLTextAreaElement).setCustomValidity('');
            setInvoiceDetailsValue(e.target.value)
          }}

            onInvalid={(e) => { (e.target as HTMLTextAreaElement).setCustomValidity('Wprowadz dane do faktury'); }} />
          <span className="text-sm text-neutral-500 block">

          </span>
        </div>

      </div>
    else {

      return (
        <div></div>
      )
    }

  }

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    img: string,
    value: string,
    defaultChecked?: boolean,
  ) => {
    return (
      <div className="flex items-center">
        <input
          onChange={(e) => { setPaymentMethodValue({ value: e.target.value, text: label }) }}
          defaultChecked={defaultChecked}
          id={id + name}
          value={value}
          name={name}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        ></input>


        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          <div className="rounded-xl ring ring-primary-6000 border-neutral-700 grid items-center text-center border-neutral-300" style={{ backgroundColor: "rgba(255,255,255,0.9)", verticalAlign: "bottom", width: 150, height: 130 }}>
            <img src={img} className="ml-2" style={{ padding: 5 }}></img>
            <div style={{ color: "rgb(15,15,15)" }}>{label}</div>
          </div>
        </label>

      </div>
    );
  };


  const renderConfirmButton = () => {

    if (paymentMethod.value == "0" || paymentMethod.value == "-1")
      return (
        <ButtonPrimary type="submit">Potwierdź</ButtonPrimary>
      )

    if (paymentMethod.value != "0")
      return (
        <ButtonPrimary type="submit">{paymentMethod.text}</ButtonPrimary>
      )

  };

  const renderPaymentMethods = (methods: any[]) => {

    // for(var i=0;i<methods.length;i++)
    // {
    //   for(var m=0;m<methods[i].length;m++)
    //   {
    //     methods[i][m].id;
    //     methods[i][m].name;
    //     methods[i][m].description;
    //     methods[i][m].status;
    //     <img src={methods[i][m].image}></img>
    //   }  

    // }
    if(price == "0")
    return (<div></div>)
    
    return (

      


      <div>
        <h2 className="mb-5 text-2xl font-semibold">Metoda płatności</h2>
        <div className="text-neutral-6000 dark:text-neutral-300">


          <label className="text-lg font-semibold" htmlFor="">
            Przelew na konto
          </label>
          <br />
          <div className="mt-4 flex">

            {<input
              id={"0pay"}
              value="0"
              name={"pay"}
              type="radio"
              defaultChecked
              onChange={() => setPaymentMethodValue({ value: "0" })}
              className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
            ></input>}

            {
              <label
                htmlFor={"0pay"}
                className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >

                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  <strong> 06 1140 2004 0000 3102 7987 2754</strong> <span>(rachunek do przelewu)</span>
                </span>

              </label>}

          </div>




        </div>





        {methods.map((type: any, index: any) => (

          <div key={type.type} className="mt-10">
            <label className="text-lg font-semibold" htmlFor="">
              {type.type == "PBL" ? "Szybkie przelewy - Pay By Link" : type.type}
            </label>
            <br />
            <div className="mt-4 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-5">
              {
                type.paymentMethods
                  .filter((x: any) => x.status == "ENABLED")
                  .map((m: any, ind: any) => (
                    <div key={m.id}>
                      <div>
                        {renderRadio("pay", m.id, m.description, m.image, m.id)}
                      </div>
                    </div>
                  ))

              }

            </div>

          </div>

        ))
        }

      </div>

    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Przykładowe zagadnienia</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <div id="desc" dangerouslySetInnerHTML={{ __html: (description) }} />
        </div>
      </div>
    );
  };

  const renderPrice = () =>{

    if(price !="100")
    return (<><span className="text-3xl font-semibold line-through font-str">100zł</span>
    <span className="ml-5 text-3xl font-semibold text-lime-400 font-str">{price}zł</span></>)
    else 
    return <span className="text-3xl font-semibold">{price}zł</span>

  }

  const renderPromoCodeValidationMsg = ()=>
  {
    if(promoCodeMsg)
    return (<span className="ml-5 flex-1 text-xs text-red-400">Podany kod jest nieaktualny. </span>)
  }

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Formularz rejestracji
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>

            <div className="space-y-6">
              <div className="flex flex-col sm:flex-row sm:items-center">
                <div className="flex-shrink-0 w-full sm:w-40">
                  <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                    <NcImage src={"/images/course/" + image} />
                  </div>
                </div>
                <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      Temat
                    </span>
                    <span className="text-base sm:text-lg font-medium mt-1 block">
                      {title}

                    </span>
                  </div>
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    on-line · MS Teams
                  </span>

                </div>
              </div>

              <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                <div className="flex-1 p-5 flex space-x-4">
                  <svg
                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                      stroke="#D1D5DB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Data szkolenia</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {/* TIME - NAME */}

                      <div className="font-medium text-lg">{new Date(date).toLocaleDateString("pl-PL")}</div>
                      <div className="text-sm text-neutral-500 font-normal mt-0.5">
                        {(new Date(date).toLocaleString('pl-pl', { weekday: 'long' }))}
                      </div>
                      <div className="text-sm text-neutral-500 font-normal mt-0.5">
                        {start} - {end}
                      </div>

                    </span>
                  </div>
                </div>
                <div className="flex-1 p-5 flex space-x-4">
                  <svg
                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                      stroke="#D1D5DB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Liczba osób</span>
                    <span className="mt-1.5 text-lg font-semibold">1</span>
                  </div>
                </div>
              </div>

              {renderSection2()}
            </div>
            <div className="py-5 mt-6">
              <Tab.Group>
                <Tab.List className="flex my-5 gap-1">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          }`}
                      >
                        Zapis indywidualny
                      </button>
                    )}
                  </Tab>
                  {/* <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                          }`}
                      >
                        <span className="mr-2.5">Zapis grupowy</span>
                      </button>
                    )}
                  </Tab> */}
                </Tab.List>

                <Tab.Panels>


                  <Tab.Panel className="space-y-5">
                    <form action={"/pay-done/"} onSubmit={(e) => {

                      
                      e.preventDefault();

                      var cb1 = document.getElementsByName('privacy-cb')[0] as HTMLInputElement;

                      if (!cb1.checked) {
                        (cb1 as HTMLInputElement).setCustomValidity('Zaznacz zgodę');
                        e.preventDefault();
                        return;
                      }
                      (cb1 as HTMLInputElement).setCustomValidity('');

                      var cb2 = document.getElementsByName('regulations-cb')[0] as HTMLInputElement;

                      if (!cb2.checked) {
                        (cb2 as HTMLInputElement).setCustomValidity('Zaznacz zgodę');
                        e.preventDefault();
                        return;
                      }
                      (cb2 as HTMLInputElement).setCustomValidity('');

                      new RegistrationServiceClient().new(params.id, firstName, lastName, email, needsInvoice, invoiceDetails, paymentMethod.value, additionalInfo, price != "100" ? promoCode : "", (s: string) => {
                        if (paymentMethod.value == "0" || paymentMethod.value == "-1")
                          window.location.href = "/pay-done/";
                        else {
                          new PaymentServiceClient().link(s, paymentMethod.value, (payLink: string) => {
                            window.location.href = payLink;
                          });
                        }
                      }
                      );


                    }}>
                      <div className="w-24 border-b border-neutral-200 dark:border-neutral-700"></div>


                      <div className="pt-10 pb-10">
                        {<Checkbox label="Chcę otrzymać fakturę" name="invoice" onChange={(e) => { setNeedInvoice(e); }} />}
                      </div>



                      {renderInvoiceFields()}


                      <h3 className="text-2xl font-semibold">Dane uczestnika</h3>
                      <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                        Poniższe dane będą wykorzystane do wygenerowania certyfikatów i wysłania ich na wskazany adres.
                      </span>
                      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>


                      <div className="flex-grow mt-10 md:mt-0 space-y-6">
                        <div>
                          <Label>Imię</Label>
                          <Input required aria-required name="firstName" className="mt-1.5" defaultValue="" onChange={(e) => {
                            (e.target as HTMLInputElement).setCustomValidity('');
                            setFirstNameValue(e.target.value)
                          }}

                            onInvalid={(e) => { (e.target as HTMLInputElement).setCustomValidity('Podaj imię'); }} />
                        </div>

                        {/* ---- */}
                        <div>
                          <Label>Nazwisko</Label>
                          <Input required aria-required name="lastName" className="mt-1.5" defaultValue="" onChange={(e) => {
                            (e.target as HTMLInputElement).setCustomValidity('');
                            setLastNameValue(e.target.value)
                          }}
                            onInvalid={(e) => { (e.target as HTMLInputElement).setCustomValidity('Podaj nazwisko'); }} />
                        </div>
                        {/* ---- */}
                        <div>
                          <Label>Email</Label>
                          <Input type="email" required className="mt-1.5" defaultValue="" onChange={(e) => {
                            (e.target as HTMLInputElement).setCustomValidity('');
                            setEmailValue(e.target.value)
                          }}
                            onInvalid={(e) => { (e.target as HTMLInputElement).setCustomValidity('Podaj email'); }} />
                        </div>
                      </div>

                      <div className="my-5 space-y-1">
                        <Label>Dodatkowe informacje </Label>
                        <Textarea
                          onChange={(e) => { setAdditionalInfoValue(e.target.value) }}
                          placeholder="..." />
                        <span className="text-sm text-neutral-500 block">

                        </span>
                      </div>

                      <div className="space-y-6">
                        <h3 className="text-2xl font-semibold">Podsumowanie</h3>

                        <div className="flex flex-col space-y-4">

                          <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Kod promocyjny</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                              <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                                <Input className="!h-full" placeholder="" onChange={(e) => {
                                  (e.target as HTMLInputElement).setCustomValidity('');
                                  setPromoCodeValue(e.target.value);
                                }} />
                                <ButtonPrimary className="flex-shrink-0" onClick={ (e)=>
                                  {
                                    e.preventDefault();
                                    new PromoCodeServiceClient().promoCodeCheck(promoCode, 100, (r:any)=>
                                    {
                                      
                                      showPromoCodeMessage(r.discount == 0);
                                      setPriceValue(r.newPrice)
                                    });
                                  }}>
                                  <i className="text-xl las la-plus"></i>
                                  <span className="ml-3">Zastosuj</span>
                                </ButtonPrimary>
                              </div>
                              
                              { renderPromoCodeValidationMsg()}
                                  
                              
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col space-y-4">

                          <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Do zapłaty</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            
                            
                            {renderPrice()}

                            </span>
                          </div>
                        </div>
                        {
                          
                          renderPaymentMethods(paymentMethods)
                        }
                      </div>
                      <div className="flex pt-10">
                        {<Checkbox label="" name="privacy-cb" onChange={(v, el) => { el.setCustomValidity(''); }} />
                        }
                        <span className="pl-4">* Akceptuję <a href='/privacy' className="underline" >Politykę Prywatności</a></span>
                      </div>
                      <div className="flex pt-4">
                        {
                          <Checkbox label="" name="regulations-cb" onChange={(v, el) => { el.setCustomValidity(''); }} />
                        }
                        <span className="pl-4">* Akceptuję <a href='/regulations' className="underline" >Regulamin Sklepu Internetowego</a></span>
                      </div>

                      <div className="pt-8">
                        {renderConfirmButton()}
                      </div>
                    </form>
                  </Tab.Panel>
                  <Tab.Panel className="space-y-5">
                    <div className="w-24 border-b border-neutral-200 dark:border-neutral-700"></div>

                    <br />

                    <h3 className="text-2xl font-semibold">Dane uczestników</h3>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      Poniższe dane będą wykorzystane do wygenerowania certyfikatów i wysłania ich na wskazany adres.
                    </span>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                    <form>
                      <div className="flex space-x-1">
                        <div className="flex-1 space-y-1">
                          <Label>Imię </Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label>Nazwisko</Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label>Email</Label>
                          <Input />
                        </div>
                      </div>
                      <div className="flex space-x-1">
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                      </div>
                      <div className="flex space-x-1">
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                        <div className="flex-1 space-y-1">
                          <Label></Label>
                          <Input />
                        </div>
                      </div>

                      <div className="my-5 space-y-1">
                        <Label>Dodatkowe informacje </Label>
                        <Textarea placeholder="..." />
                        <span className="text-sm text-neutral-500 block">

                        </span>
                      </div>
                    </form>
                    <div className="space-y-6">
                      <h3 className="text-2xl font-semibold">Podsumowanie</h3>
                      <div className="flex flex-col space-y-4">
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Do zapłaty</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            200zł (2 x 100zł)
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Metoda płatności</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            przelew na konto
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Radunek do przelewu</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            2342 2342 2342 1111
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Tytuł przelewu</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            #1345
                          </span>
                        </div>

                      </div>
                    </div>

                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
              <div className="pt-8">
                {/* <ButtonPrimary type="submit" href={"/pay-done/" + id}>Potwierdź</ButtonPrimary> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:pr-10 ">{renderMain()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
