import React, { FC, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import PropertyCardH from "components/PropertyCardH/PropertyCardH";
import CourseServiceClient from "services/courseServiceClient";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {

  const [courseList, setCourseList] = useState<any[] | []>([]);

  useEffect(() => {

    new CourseServiceClient().get((data:any[]) => {
      setCourseList(data);
    });
  }, []);


  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Katalog szkoleń"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            2024
            <span className="mx-2"></span>
            
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        {/* <TabFilters /> */}
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 ">
        {courseList.map((stay) => (
          <PropertyCardH key={stay.closestEventId} data={stay} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
