import React from "react";
import CookieConsent from "react-cookie-consent";
import { GlobalLoading } from "react-global-loading";
import MyRouter from "routers/index";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { CartProvider } from "services/cartContext";
import { Slide, ToastContainer, toast } from 'react-toastify';


function App() {
  return (
    <CartProvider>
<ToastContainer
/>

    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
      <GlobalLoading />
      <MessengerCustomerChat
    pageId="106238444359473"
    appId="900317404599295"
    loggedInGreeting="Chętnie odpowiem na wszystkie pytania dotyczące szkoleń :)"
    loggedOuGreeting="Chętnie odpowiem na wszystkie pytania dotyczące szkoleń :)"
    greetingDialogDisplay = "fade"
    greetingDialogDelay={9}
    minimized={false}
    language= 'pl_PL'
  />,
     <div id="cookieConsent">
     <CookieConsent location="bottom" 
      buttonText="Rozumiem"
      disableButtonStyles={true}
      style={{ background: "rgba(25,25,25,0.9)", borderRadius:"6px"}}
      buttonClasses="mb-4 ml-4 nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base lg:text-lg font-medium px-6 py-3 lg:px-8 lg:py-4 rounded-xl  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0"     
      containerClasses="flex-grid lg:p-10 grid grid-cols-1 gap-6"
      contentClasses="text-capitalize"
 
>
<div>
    Ta strona wykorzystuje pliki cookies. Korzystając z niej, zgadzasz się na wykorzystywanie ciasteczek.
    <a href="https://mamtemoc.edu.pl/polityka-prywatnosci">Więcej informacji znajdziesz w naszej polityce prywatności.</a>
</div>
  
</CookieConsent>
</div>
      
    </div>
    </CartProvider>
  );
}

export default App;
