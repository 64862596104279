import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 items-center relative text-center"> */}
      <div className="container flex flex-wrap">
      
        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7 mb-10">
          
          <ul className="text-neutral-500 dark:text-neutral-400 list-disc text-left ml-x-15">
              <li className="">
                a doktor nauk społecznych w dziedzinie pedagogiki, terapeuta, nauczyciel mianowany edukacji wczesnoszkolnej,</li>
             <li>wieloletnia wykładowczyni, adiunkt na Wydziale Pedagogicznym Uniwersytetu Warszawskiego, koordynator praktyk przedszkolnych i wczesnoszkolnych, opiekun Koła Naukowego Wczesnej Edukacji WPUW</li> 
             <li>prowadząca szkolenia dla nauczycieli edukacji przedszkolnej,  wczesnoszkolnej, szkół podstawowych i liceów, wspomagania rad, m.in. w Warszawskiem Centrum Innowacji Edukacyjnych i Społecznych (WCIES), Mazowieckim Samorządowym Centrum Doskonalenia Nauczycieli (MSCDN), Ośrodku Rozwoju Edukacji (ORE)</li>
              <li>autorka książek i artykułów naukowych dotyczących edukacji początkowej, podręczników dla dzieci, scenariuszy zajęć dla uczniów, materiałów dydaktycznych dla nauczycieli</li>
              <li>prywatnie szczęśliwa żona i mama trzech synków.</li>
            </ul>
            </div>
            <div className="lg:ml-10">
          <img className="rounded-xl" src={"/images/ola.jpeg"} alt="" />
        </div> 
        </div>

      </div>
    
  );
};

export default SectionHero;
