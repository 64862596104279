import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import createDOMPurify from 'dompurify';
import Checkbox from "shared/Checkbox/Checkbox";
import Label from "components/Label/Label";
import CertServiceClient from "services/certServiceClient";
import { CheckBadgeIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import FormItem from "containers/PageAddListing1/FormItem";
import Input from "shared/Input/Input";
import { Dialog } from "@headlessui/react";
import EventServiceClient from "services/eventServiceClient";
import RegistrationServiceClient from "services/registrationServiceClient";
import Textarea from "shared/Textarea/Textarea";


export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    airlines: {
      logo: string;
      name: string;
    };
    price: string;
  };
}

const OverviewCard: FC<any> = ({
  className = "",
  data,
  defaultOpen = false,
  cb
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const DOMPurify = createDOMPurify(window);
  const [startDate, setStartDate] = useState<any>(null);
  const [updateOption, setUpdateOption] = useState<any>(null);
  const [isConfirmOpen, setConfirmIsOpen] = useState(false);
  const [isSaveVisible, setSaveVisibility] = useState(false);
  const [firstName, setFirstNameValue] = useState<string>("");
  const [lastName, setLastNameValue] = useState<string>("");
  const [email, setEmailValue] = useState<string>("");
  const [invoiceDetailsValue, setInvoiceDetailsValue] = useState<string>("");
  const [bulkRegister, setBulkRegister] = useState<string>("");
  
  useEffect(() => {

    //debugger;
    setStartDate(new Date(data.date));

  }, []);

  function saveUpdate() {

    if (updateOption == "move") {
      new EventServiceClient().move(data.idEvent, startDate, () => {
        cb();
      });
    }
    else {
      new EventServiceClient().clone(data.idEvent, startDate, () => {
        cb();
      });
    }
  }

  function register() 
  {
    debugger;
    new RegistrationServiceClient().anewbulk(data.idEvent, bulkRegister, ()=>
    {
      cb();
    } )

    // new RegistrationServiceClient().anew(data.idEvent, firstName, lastName, email, 0, '', 0, ()=>
    // {
    //   cb();
    // } )



  }

  function saveRegistration() {

    if (updateOption == "move") {
      new EventServiceClient().move(data.idEvent, startDate, () => {
        cb();
      });
    }
    else {
      new EventServiceClient().clone(data.idEvent, startDate, () => {
        cb();
      });
    }
  }  

  function copyEmails(data: any) {

    var checkboxList = document.getElementsByName("row-check-" + data.idEvent);

    var sa = "";

    for (var i = 0; i < checkboxList.length; i++)
      if ((checkboxList[i] as any).checked) {
        sa += data.members[i].email + ";";
      }
    sa = sa.slice(0, -1);
    navigator.clipboard.writeText(sa);
    alert(sa);
  }

  function sendCerts(data: any) {

    var checkboxList = document.getElementsByName("row-check-" + data.idEvent);

    var sa = "";

    var s = new CertServiceClient();

    for (var i = 0; i < checkboxList.length; i++)
      if ((checkboxList[i] as any).checked) {
        s.send(data.idEvent, data.members[i].email, () => {
          console.log("wysłano certyfikat " + data.members[i].email);

        });
      }
  }

  function certPreview(data: any, index: any) {

    new CertServiceClient().preview(data.idEvent, data.members[index].email);
  }

  function selectAll(x: any, rowName: any) {
   
 
    var checkboxList = document.getElementsByName(rowName);
    for (var i = 0; i < checkboxList.length; i++)
    {
      debugger;
      (checkboxList[i] as any).checked = x;
    }
  }

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    opt: string,
    defaultChecked: boolean
  ) => {
    return (
      <div className="flex items-center">
        <input onClick={() => { setUpdateOption(opt); setSaveVisibility(true) }}
          defaultChecked={defaultChecked}
          id={id + name}
          name={name}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };


  const renderDetail = (data: any) => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">



        <h2 className="text-2xl font-semibold">Rejestracja</h2>
        <div className="mt-10 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <form>
<div>
<div className="mb-5">
          <Textarea className="mt-10" required placeholder="dowolna liczba wierszy w formacie Imię  Nazwisko  Email     (kolumny oddziel więcej niż 1 spacją)" onChange={(e) => {
            (e.target as HTMLTextAreaElement).setCustomValidity('');
            setBulkRegister(e.target.value)
          }}

            onInvalid={(e) => { (e.target as HTMLTextAreaElement).setCustomValidity('Wprowadz dane do faktury'); }} />
          <span className="text-sm text-neutral-500 block">

          </span>
        </div>

</div>



          <div className="mt-10 flex space-x-1">
            <div className="flex-1 space-y-1">
              <Label>Imię </Label>
              <Input onChange={ (e)=> setFirstNameValue(e.target.value) }/>
            </div>
            <div className="flex-1 space-y-1">
              <Label>Nazwisko</Label>
              <Input onChange={ (e)=> setLastNameValue(e.target.value) } />
            </div>
            <div className="flex-1 space-y-1">
              <Label>Email</Label>
              <Input onChange={ (e)=> setEmailValue(e.target.value) } />
            </div>
          </div>
        </form>
        <div className="flex justify-center items-center">
          <div>
            <ButtonPrimary onClick={() => register()} className="mt-5" href="#" >Dodaj</ButtonPrimary>
          </div>
          {/* <span className="px-2"></span>
          <ButtonSecondary href={"/event/" + data.idEvent}>Więcej szczegółów</ButtonSecondary> */}
        </div>


        <h2 className="mt-10 text-2xl font-semibold">Edycja</h2>
        <div className="mt-10 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="flex mt-6 justify-center items-center">


          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-5">
            {renderRadio( "edit_" + data.idEvent, "Do", "Przenieś szkolenie na inny termin", "move", false)}
            {renderRadio( "edit_" + data.idEvent, "Allow", "Utwórz podobne szkolenie w innym terminie", "clone", false)}
          </div>


        </div>
        <div className="flex mt-6 justify-center items-center">



          <div className="grid grid-cols-1 md:grid-cols-1">
            <div className={isSaveVisible ? "" : "hidden"}>
              <FormItem label="">
                <DatePicker dateFormat="dd.MM.yyyy"
                  customInput={<Input />}
                  //selected={ }
                  selected={startDate}
                  onChange={(d) => { setStartDate(d) }} //{onChangeDate}

                />
              </FormItem>
            </div>

          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className={isSaveVisible ? "" : "hidden"}>
            <ButtonPrimary onClick={() => saveUpdate()} className="mt-5" href="#" >Zapisz</ButtonPrimary>
          </div>
          {/* <span className="px-2"></span>
          <ButtonSecondary href={"/event/" + data.idEvent}>Więcej szczegółów</ButtonSecondary> */}
        </div>






        <div className="pl-4 member-row">
          <div className="mt-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 gap-5">



          </div>
        </div>


        <h2 className="text-2xl font-semibold">Zapisy</h2>
        <div className="mt-10 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="mt-10 text-neutral-6000 dark:text-neutral-300"></div>

        <div className="pl-4 member-row">
            <div className="mb-10 mt-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 gap-5">

              <Checkbox label="" name="select-all" onChange={(x) => { selectAll(x, "row-check-" + data.idEvent) }}  />
   


            </div>
          </div>


        {data.members.map((item: any, index: any) => (
          <div className="pl-4 member-row">
            <div className="mt-6 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 gap-5">

              <Checkbox key={data.regId} label="" name={"row-check-" + data.idEvent} className={"row-check-" + data.idEvent} />
              <Label >{item.name}</Label>
              <Label >{item.surname}</Label>
              <Label >{item.email}</Label>
              <Label className="ml-5" >{item.invoiceDetails}</Label>


              <CheckBadgeIcon onClick={() => { certPreview(data, index) }} height={25} className="icoLink"></CheckBadgeIcon>


            </div>
          </div>
        ))}



        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary href="#" onClick={() => { copyEmails(data) }}>Kopiuj maile do schowka</ButtonPrimary>
          <span className="ml-5"></span>
          <ButtonSecondary href="#" onClick={() => { sendCerts(data) }}>Generuj i wyślij certyfikaty</ButtonSecondary>

          {/* <span className="px-2"></span>
          <ButtonSecondary href={"/event/" + data.idEvent}>Więcej szczegółów</ButtonSecondary> */}
        </div>

      </div>
    );
  };

  const renderSubtitle = (closed:boolean) =>{

    if(closed)
    return (<span className="text-sm font-semibold text-lime-400 font-str">SZKOLENIE ZAMKNIĘTE</span>);
    else
    return (<span className="text-sm text-neutral-500 font-normal mt-0.5">
              
            </span>);
  }

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div onClick={() => setIsOpen(!isOpen)}
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">

            <div className="relative flex-shrink-0 w-24 h-24 rounded-2xl overflow-hidden"><div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
              <img src={"/images/course/" + data.image} className="object-cover w-full h-full" alt="nc-imgs" />


            </div>
            </div>


            {/* <img src={data.airlines.logo} className="w-10" alt="" /> */}
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                {/* TIME - NAME */}

                <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {(new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' }))}
                </div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {data.start} - {data.end}
                </div>


              </div>
            </div>

          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">{new Date(data.date).toLocaleDateString("pl-PL")}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {(new Date(data.date).toLocaleString('pl-pl', { weekday: 'long' }))}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.start} - {data.end}
            </div>
          </div>

          {/* TIMME */}
          <div className=" lg:block flex-[10]">
            <div className="font-medium text-lg">
              {data.title}
            </div>
            {renderSubtitle(data.isClosed)}
          </div>


          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-600">
                {data.members.length} os.
                {/* {data.price}zł */}
              </span>
            </div>
            {/* <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              za osobę
            </div> */}
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail(data)}
    </div>
  );
};

export default OverviewCard;

