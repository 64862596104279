import axios from 'axios';
import AuthServiceClient from './authServiceClient';
import ClientConfig from './clientConfig';

export class CertServiceClient {
    authService = new AuthServiceClient();
   
    async preview(eventId, memberId, callback){

        try {
            await
                axios.get(ClientConfig.ApiBaseUrl + "certificate/preview/" + eventId +"/"+memberId + "/false",
                    
                    {
                        responseType: 'arraybuffer',
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Accept': 'application/pdf',
                            'Authorization': this.authService.authToken()
                        }
                    }
                ).then(response => {

                    var file = new Blob([response.data], {type: 'application/pdf'});
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                   //callback(response.data);
                });
        }
        catch (e) {
            console.log('CertService error.', e);
        }
    }

    async send(idEvent, memberId, callback) {

        try {
            axios.post(ClientConfig.ApiBaseUrl + "certificate/send/",
                JSON.stringify(
                    {
                        "EventId": idEvent,
                        "email": memberId
                    }),
                { headers: { 
                'Content-Type': 'application/json',
                'Authorization': this.authService.authToken() } }
            ).then(response => {
                callback();
            });

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }

 
}

export default CertServiceClient;
