import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >



      
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          kto? co? jak?
        </span>
        <h2 className="font-semibold text-4xl mt-5">MAM TĘ MOC</h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <span className="block text-xl font-semibold">
              dr Aleksandra Szyller
            </span>
            <ul className="text-neutral-500 dark:text-neutral-400 list-disc">
              <li>
               1 doktor nauk społecznych w dziedzinie pedagogiki, terapeuta, nauczyciel mianowany edukacji wczesnoszkolnej,</li>
             <li>wieloletnia wykładowczyni, adiunkt na Wydziale Pedagogicznym Uniwersytetu Warszawskiego, koordynator praktyk przedszkolnych i wczesnoszkolnych, opiekun Koła Naukowego Wczesnej Edukacji WPUW</li> 
             <li>prowadząca szkolenia dla nauczycieli edukacji przedszkolnej,  wczesnoszkolnej, szkół podstawowych i liceów, wspomagania rad, m.in. w Warszawskiem Centrum Innowacji Edukacyjnych i Społecznych (WCIES), Mazowieckim Samorządowym Centrum Doskonalenia Nauczycieli (MSCDN), Ośrodku Rozwoju Edukacji (ORE)</li>
              <li>autorka książek i artykułów naukowych dotyczących edukacji początkowej, podręczników dla dzieci, scenariuszy zajęć dla uczniów, materiałów dydaktycznych dla nauczycieli</li>
              <li>prywatnie szczęśliwa żona i mama trzech synków.</li>
            </ul>
          </li>
          <li className="space-y-4">
            <span className="block text-xl font-semibold">
              Tematyka
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Zapraszam na samodzielnie prowadzone, autorskie szkolenia dla nauczycieli edukacji przedszkolnej i wczesnoszkolnej. Tematy prowadzonych przeze mnie szkoleń oraz poruszane w ramach ich realizacji zagadnienia znajdą Państwo w katalogu SZKOLENIA.
            Jeżeli nie znaleźli Państwo poszukiwanego tematu szkolenia, proszę o kontakt mailowy. Możliwe szkolenia także z innych tematów.
            </span>
          </li>
          <li className="space-y-4">
            <span className="block text-xl font-semibold">
              Webinaria
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            Wszystkie szkolenia poniżej są prowadzone on-line. 
            Szkolenia organizowane są w formie webinariów na żywo.
            W trakcie webinariów istnieje możliwość komunikacji za pomocą mikrofonu, jak i komunikatora.
            Nie ma konieczności włączania kamery. Po szkoleniu wysyłam Państwu prezentacje oraz wszelkie materiały.
            Koszt szkolenia on-line wynosi 100 zł. Istnieje możliwość wystawienia faktury VAT 0% (także na osoby fizyczne oraz z odroczoną płatnością).Każde szkolenie może być zroganizowane w formie stacjonarnej, z dojazdem do placówki:) Koszt ustalany jest indywidualnie.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
