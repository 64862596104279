// CartService.js
export class CartService {
    addItem(id, data, addItemFunc) {
      return addItemFunc({id: id, data: data});
    }
  
    removeItem(id, removeItemFunc) {
      removeItemFunc(id);
    }

    getItems(getItemsFunc) {
      return getItemsFunc();
    }
  }
  
  const instance = new CartService();
  Object.freeze(instance);
  
  export default instance;
  