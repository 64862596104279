import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero3 from "components/SectionHero/SectionHero3";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import SectionGridFilterCard from "containers/ListingFlightsPage/SectionGridFilterCard";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHero from "containers/PageAbout/SectionHero";
import Heading from "components/Heading/Heading";
import FlightCard from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import EventServiceClient from "services/eventServiceClient";
import EventModel from "services/models/eventModel";
import OverviewCard from "components/FlightCard/OverviewCard";
import NcModal from "shared/NcModal/NcModal";
import WidgetCategories from "containers/BlogPage/WidgetCategories";




function RegHome() {
  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-purple-blueGrey");
    return () => {
      $body.classList.remove("theme-purple-blueGrey");
    };
  }, []);


  const [participantsList, setParticipants] = useState<any[] | []>([]);

  useEffect(() => {
    new EventServiceClient().getParticipants((data: any) => {
      setParticipants(data);
    });
  }, []);



  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container max-w-27xl mx-auto">

      </div>


    
      <div className="container flex">
    

</div>      

      <div className="container relative mt-24 mb-24">
      <div
      className={`nc-SectionGridFilterCard className="clist pb-24 lg:pb-28 mt-10"`}
      data-nc-id="SectionGridFilterCard"
    >

      <Heading isCenter desc="">
        Uczestnicy
      </Heading>


      <WidgetCategories items={participantsList} />
    </div>


 

      </div>
    </div>
  );
}

export default RegHome;
