import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Strona główna",
    admin: false,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/catalog",
    name: "Katalog szkoleń",
    admin: false,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Kontakt",
    isNew: true,
    admin: false,
  },
  {
    id: ncNanoId(),
    href: "/overview",
    name: "Pomoce",
    type: "dropdown",
    isNew: true,
    admin: true,
    children: [
      {
        id: ncNanoId(),
        href: "/overview",
        name: "Zaplanuj szkolenia",        
        admin:true,
  },  
      {
    id: ncNanoId(),
    href: "/reg",
    name: "Uczestnicy",        
    admin:true,
}]
},

  
  // {
  //   id: ncNanoId(),
  //   href: "/unique_id_",
  //   name: "Five columns",
  //   type: "megaMenu",
  //   megaMenu: megaMenuDemo,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/listing-stay",
  //   name: "Listing Page",
  //   type: "dropdown",
  //   isNew: true,
  //   children: [
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-stay",
  //       name: "Stay listing",
  //       type: "dropdown",
  //       children: [
  //         { id: ncNanoId(), href: "/listing-stay", name: "Stay page" },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-stay-map",
  //           name: "Stay page (map)",
  //         },
  //         { id: ncNanoId(), href: "/listing-stay-detail", name: "Stay Detail" },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-experiences",
  //       name: "Experiences listing",
  //       type: "dropdown",
  //       children: [
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences",
  //           name: "Experiences page",
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences-map",
  //           name: "Experiences page (map)",
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-experiences-detail",
  //           name: "Experiences Detail",
  //         },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-car",
  //       name: "Cars listing",
  //       type: "dropdown",
  //       children: [
  //         { id: ncNanoId(), href: "/listing-car", name: "Cars page" },
  //         { id: ncNanoId(), href: "/listing-car-map", name: "Cars page (map)" },
  //         { id: ncNanoId(), href: "/listing-car-detail", name: "Car Detail" },
  //       ],
  //     },

  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-real-estate",
  //       name: "Real Estate Listings",
  //       isNew: true,
  //       type: "dropdown",
  //       children: [
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-real-estate",
  //           name: "Real Estate Listings",
  //           isNew: true,
  //         },
  //         {
  //           id: ncNanoId(),
  //           href: "/listing-real-estate-map",
  //           name: "Real Estate Maps",
  //           isNew: true,
  //         },
  //       ],
  //     },
  //     //
  //     {
  //       id: ncNanoId(),
  //       href: "/listing-flights",
  //       name: "Flights listing",
  //       isNew: true,
  //     },
  //   ],
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/author",
  //   name: "Templates",
  //   type: "dropdown",
  //   children: templatesChildrenMenus,
  // },

  // {
  //   id: ncNanoId(),
  //   href: "/blog",
  //   name: "Other pages",
  //   type: "dropdown",
  //   children: otherPageChildMenus,
  // },
];
